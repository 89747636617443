<template>
    <div>
        <b-overlay :show="showLoader">
            <b-card :title="$t('invoices.issued_invoices')">
                <b-row>
                    <b-col>
                        <b-form-group :label="$t('general.date_from')">
                            <date-picker :locale="currentLocale" :first-day-of-week="2" @input="trackDateChange" :max-date='dateTo' disabled-dates is24hr v-model="dateFrom" :model-config="modelConfig" :masks="{ input: ['DD. MM. YYYY'], data: ['DD. MM. YYYY'] }" :is-required="true">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input class="form-control"
                                           :value="inputValue"
                                           v-on="inputEvents"
                                    />
                                </template>
                            </date-picker>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group :label="$t('general.date_to')">
                            <date-picker :locale="currentLocale" :first-day-of-week="2" @input="trackDateChange" :min-date="dateFrom" disabled-dates is24hr v-model="dateTo" :model-config="modelConfig" :masks="{ input: ['DD. MM. YYYY'], data: ['DD. MM. YYYY'] }" :is-required="true">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input class="form-control"
                                           :value="inputValue"
                                           v-on="inputEvents"
                                    />
                                </template>
                            </date-picker>
                        </b-form-group>
                    </b-col>
                </b-row>

            </b-card>
            <InvoiceStatistics :date-from="dateFrom" :date-to="dateTo" ref="invoiceStatistics" invoice-type="issued"></InvoiceStatistics>
            <b-card class="my-1 d-flex align-center">
                <Table ref="invoicesTable" class="mt-1" v-if="!showLoader" :columnDefs="columnDefsIssuedInvoices" :rowData="issuedInvoices" @edit="edit" @refundIssuedInvoice="refundIssuedInvoice"/>
            </b-card>
        </b-overlay>
        <UsedAtModal ref="refundModal" @confirm="confirmRefund" @cancel="cancelRefund">
            <p v-if="invoiceReference && invoiceAmount">
                {{this.$t('invoices.refund_message_1')}} <b>{{invoiceReference}}</b>
                {{this.$t('invoices.refund_message_2')}} <b>{{invoiceAmount}}</b>€ ?
            </p>
        </UsedAtModal>
    </div>
</template>

<script>
    import Table from '@/views/components/Table/Table.vue'
    import {BCard, BOverlay, BFormGroup, BRow, BCol} from 'bootstrap-vue'
    import {DatePicker} from 'v-calendar'
    import * as Sentry from '@sentry/vue'
    import InvoiceStatistics from '@/views/Invoices/Issued/components/InvoiceStatistics.vue'
    import {Invoice} from '@/libs/enums/Invoice'
    import UsedAtModal from '@/views/components/UsedAtModal.vue'

    export default {
        components:{
            UsedAtModal,
            InvoiceStatistics,
            BFormGroup,
            BCard,
            BOverlay,
            Table,
            DatePicker,
            BRow,
            BCol
        },
        computed: {
            statusesEditable() {
                return [
                    {id: Invoice.InProgress, name: this.$t('invoices.in_progress'), variant: 'secondary'},
                    {id: Invoice.Issued, name: this.$t('invoices.issued'), variant: 'info'},
                    {id: Invoice.PartiallyPaid, name: this.$t('invoices.partially_paid'), variant: 'warning'},
                    {id: Invoice.Paid, name: this.$t('invoices.paid'), variant: 'success'}
                ]
            },
            statuses() {
                return [
                    {id: Invoice.InProgress, name: this.$t('invoices.in_progress'), variant: 'secondary'},
                    {id: Invoice.Issued, name: this.$t('invoices.issued'), variant: 'info'},
                    {id: Invoice.PartiallyPaid, name: this.$t('invoices.partially_paid'), variant: 'warning'},
                    {id: Invoice.Paid, name: this.$t('invoices.paid'), variant: 'success'},
                    {id: Invoice.Refund, name: this.$t('invoices.refund'), variant: 'danger'},
                    {id: Invoice.RefundParent, name: this.$t('invoices.refund_parent'), variant: 'dark'}
                ]
            },
            columnDefsIssuedInvoices() {
                const  columnDefs = [
                    { headerName: this.$t('table_fields.uuid'), field: 'bill_id', filter: true, editable: false},
                    { headerName: this.$t('table_fields.total_price'), field: 'total_price', filter: true, filterParams: {textCustomComparator: this.$amountFilter}, editable: false, cellRenderer:'DisplayPrice'},
                    { headerName: this.$t('table_fields.date'),
                      field: 'timestamp',
                      filter: false,
                      floatingFilterComponentParams: {type: 'date'},
                      filterParams: {
                          textCustomComparator: this.$datesInRange
                      },
                      editable: false,
                      cellRenderer: 'DisplayDate'
                    },
                    {
                        headerName: this.$t('table_fields.buyer'),
                        field: 'buyer.id',
                        filter: true,
                        editable: false,
                        floatingFilterComponentParams: () => { return  {type: 'select', values: this.buyers, multiple: false } },
                        cellRenderer: (params) => this.getBuyerName(params.value)
                    },
                    {
                        headerName: this.$t('table_fields.status'),
                        editable: params => params.data.status > Invoice.InProgress && params.data.status < Invoice.Refund && this.$hasPermission(this.$permissions.InvoicesWrite),
                        field: 'status',
                        filter: false,
                        floatingFilterComponentParams:   {type: 'select', values: this.statusesEditable, multiple: false, selectValue: 'id'},
                        cellRenderer: 'DisplayStatus',
                        cellRendererParams: () => {
                            return {values: this.statuses}
                        },
                        cellEditorParams: () => {
                            return {values: this.statusesEditable}
                        },
                        cellEditorFramework: 'selectEditor'},
                    {
                        headerName: this.$t('table_fields.paid'),
                        field: 'amount_paid',
                        filter: true,
                        filterParams: {textCustomComparator: this.$amountFilter},
                        editable: params => params.data.status > 0 && params.data.status < 3,
                        colId: 'amount_paid',
                        cellEditorFramework: 'priceAndPercentageEditor',
                        cellRenderer:'DisplayPrice'
                    }
                ]

                if (this.$hasPermission(this.$permissions.InvoicesWrite)) {
                    columnDefs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 280, cellRendererParams: {button: 'MakeCopy'}}
                    )
                }
                return columnDefs
            },
            currentLocale() {
                return this.$store.getters['user/current_locale']
            }
        },
        data() {
            return {
                buyers: [],
                modelConfig: {
                    type: 'string',
                    mask: 'iso',
                    timeAdjust: '00:00'
                },
                issuedInvoices: [],
                showLoader: false,
                dateFrom: new Date(new Date().getFullYear(), 0, 1).toISOString(),
                dateTo: new Date(new Date().getFullYear(), 11, 31).toISOString(),
                filters: {},
                invoiceReference: null,
                invoiceAmount: null,
                selectedInvoiceId: null
            }
        },
        methods:{
            cancelRefund() {
                this.selectedInvoiceId = null
                this.invoiceAmount = null
                this.invoiceReference = null
            },
            async confirmRefund() {
                try {
                    await this.$http.post(`/api/client/v1/invoices/refund/${this.selectedInvoiceId}`)

                    await this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.refund'))
                }
            },
            refundIssuedInvoice(params) {
                this.invoiceReference = params.bill_id
                this.invoiceAmount = this.$numberRounding(params.total_price)
                this.selectedInvoiceId = params.id

                this.$refs.refundModal.show()
            },
            async edit(params) {
                try {
                    this.showLoader = true

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/invoices/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'Vnesli ste previsok znesek.' || err.response.data.error === 'Vnesli ste prenizek znesek.')) {
                        this.$printWarning(err.response.data.error)
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.on_load_data'))
                    }
                } finally {
                    await this.loadData()
                    this.showLoader = false
                }
            },
            async loadData() {
                try {
                    this.showLoader = true
                    const response = await this.$http.get('/api/client/v1/invoices/issued')
                    const response2 = await this.$http.get('/api/client/v1/buyers/')
                    this.issuedInvoices = response.data ?? []
                    this.buyers = response2.data ?? []
                    await this.filterData(this.dateFrom, this.dateTo)


                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    await this.$refs.invoiceStatistics.loadData()
                    this.showLoader = false
                }
            },
            getBuyerName(value) {
                if (value) {
                    const buyer = this.buyers.find(ele => ele.id === value)
                    if (buyer) {
                        return buyer.name
                    }
                }

                return '/'
            },
            //async filterData(dateFrom, dateTo) {
            //    this.issuedInvoices = this.issuedInvoices.filter((invoice) => {
            //        return new Date(invoice.timestamp) >= new Date(dateFrom) && new Date(invoice.timestamp) <= new Date(dateTo)
            //    })
            //    this.buyers = this.buyers.filter((buyer) => {
            //        return this.issuedInvoices.some((invoice) => invoice.buyer.id === buyer.id)
            //    })
            //},
            async filterData(dateFrom, dateTo) {
                const dateFromFormatted = this.$dayjs(dateFrom).startOf('day')
                const dateToFormatted = this.$dayjs(dateTo).endOf('day')

                this.issuedInvoices = this.issuedInvoices.filter((invoice) => {
                    const invoiceDate = this.$dayjs(invoice.timestamp)
                    return invoiceDate.isBetween(dateFromFormatted, dateToFormatted, null, '[]')
                })

                this.buyers = this.buyers.filter((buyer) => {
                    return this.issuedInvoices.some((invoice) => invoice.buyer.id === buyer.id)
                })
            },
            async trackDateChange() {
                if (!this.filters) {
                    this.filters = {}
                }

                const startTime = this.$dayjs(this.dateFrom).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                const endTime = this.$dayjs(this.dateTo).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

                this.filters['timestamp'] = {
                    type: 'date',
                    value: {
                        start: startTime,
                        end: endTime
                    }
                }

                this.setTableFiltersToURL()


                this.loadData()
            },
            setTableFiltersToURL() {
                if (this.filters === {} || !this.filters) {
                    return
                }

                const payloadData = this.filters

                const payload = Buffer.from(JSON.stringify(payloadData)).toString('base64')
                let payloadOld = ''
                if (this.$route.query && this.$route.query['filters']) {
                    payloadOld = this.$route.query['filters']
                }

                const type = (typeof (payloadOld))
                if (type === 'string' && payload !== payloadOld) {
                    this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, ['filters']: payload } })
                } else if (type === 'object' && payload !== payloadOld[0]) {
                    this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, ['filters']: payload } })
                }
            },
            readTableFiltersFromURL() {
                if (!this.$route.query || !this.$route.query['filters']) {
                    this.filters = {}
                    return
                }

                // get base64 date from query parameter and parse it
                const payload = this.$route.query['filters']
                const data = JSON.parse(atob(Buffer.from(payload).toString()))

                if (this.filters) {
                    this.filters = data

                    if (this.filters && this.filters['timestamp']) {
                        this.dateFrom = this.filters['timestamp'].value.start
                        this.dateTo = this.filters['timestamp'].value.end
                    }
                }
            }
          
        },
        async beforeMount() {
            await this.readTableFiltersFromURL()
        },
        async mounted() {

            await this.loadData()

        }
    }
</script>

<style scoped>

</style>